import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer className="footer text-center">
        <div className="">
          <h1></h1>
          All Rights Reserved by KLR Marketing.
          <p></p>Designed and Developed by {"Wildgoat Designs"} v43.3 06.02.2024
          <a href="/user/privacy">
            <span>&nbsp; Privacy Policy</span>
          </a>
          <a href="/user/terms">
            <span>&nbsp; Terms And Conditions</span>
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
