import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  providerAccountId: "",
  role: "1",
  company: ""

};

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {
    
    AdminChangeDraft: (state, action) => {

      state = {
        ...state,

        username: action.payload.username,
        email: action.payload.email,
        firstName: action.payload.firstname,
        lastName: action.payload.lastName,
        providerAccountId: action.payload.providerAccountId,
        role: "1",
        company: action.payload.company,

      };
      return state;
    },
  },
});

export const {
 
  AdminChangeDraft,
} = adminSlice.actions;

export default adminSlice.reducer;
