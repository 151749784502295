import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";

import groupReducer from "../reducers/groupReducer.js";
import groupsReducer from "../reducers/groupsReducer.js";
import carryReducer from "../reducers/carryReducer.js";
import carrysReducer from "../reducers/carrysReducer.js";
import userReducer from "../reducers/userReducer.js";
import currentTradeReducer from "../reducers/currentTradeReducer";
import infoReducer from "../reducers/infoReducer";
import infosReducer from "../reducers/infosReducer";
import tradesReducer from "../reducers/tradesReducer";
import navReducer from "../reducers/navReducer";
import animalTypeReducer from "../reducers/animalTypeReducer";
import animalTypesReducer from "../reducers/animalTypesReducer";
import calcReducer from "../reducers/calcReducer";
import notificationsReducer from "../reducers/notificationsReducer";
import adminReducer from "../reducers/adminReducer";

//import finalFormReducer from "../views/carry/finalFormDuck";
//import { createBrowserHistory } from "history";
//import { reducer as formReducer } from "redux-form";

//import { connectRouter } from "connected-react-router";

//export const history = createBrowserHistory();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    currentTrade: currentTradeReducer,
    trades: tradesReducer,
    user: userReducer,
    group: groupReducer,
    carry: carryReducer,
    //form: formReducer,
    //finalForm: finalFormReducer,
    info: infoReducer,
    groups: groupsReducer,
    carrys: carrysReducer,
    nav: navReducer,
    infos: infosReducer,
    animalType: animalTypeReducer,
    animalTypes: animalTypesReducer,
    calc: calcReducer,
    notifications: notificationsReducer,
    admin: adminReducer
    //router: connectRouter(createBrowserHistory()),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "carrySlice/CarryFetch",
          "BATCHING_REDUCER.BATCH",
          "carrySlice/CarryUpdate',",
        ],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        // ignoredPaths: ['items.dates'],
      },
    }),
});
