import { loadNotifications } from "./notificationActions";

import { loadGroups } from "./groupActions";

import { loadCarryS, loadCarrySByType } from "./carryActions";

import { loadTrades } from "./currentTradeActions";
import { batchActions } from "redux-batched-actions";

import axios from "axios";

import {
  UpdateUser,
  UpdateRole,
  UpdateSlug,
  UpdateAuth,
  LoadingUser,
  SavingUser,
  SavedUser,
  FetchUserError,
  UserChangeDraft,

} from "../reducers/userReducer";

import {

  AdminChangeDraft
} from "../reducers/adminReducer";

async function upateLoginSlug(dispatch, auth, userId) {
  try {
    dispatch(LoadingUser(true));

    const headers = {
      Authorization: `Bearer ${auth}`,
      accept: "application/json",
    };

    //const hardUser = "auth0|6462df2b97bd618113bb8605";

    // const options = {
    //   method: "GET",
    //   url: `https://ws.ydesign.com.au/api/users/${userId}`, //auth0|6462df2b97bd618113bb8605`,
    //   headers: { authorization: `Bearer ${auth}` },
    // };

    // axios(options)
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    //hardcodedsub auth0|6462df2b97bd618113bb8605   //6462df2b97bd618113bb8605

    const response = await axios.get(
      `https://ws.klrcalc.com.au/api/users/${userId}`, //auth0|6462df2b97bd618113bb8605`, // ${userId}`, //${process.env.REACT_APP_API_DOMAIN}/api/users/${hardUser}`,
      { headers }
    );

    var userSlug = response.data;

    const responseRole = await axios.get(
      `https://ws.klrcalc.com.au/api/users/${userId}/role`, //auth0|6462df2b97bd618113bb8605/role`, //${userId}/role`, //`${process.env.REACT_APP_API_DOMAIN}/api/users/${userId}/role`,
      { headers }
    );

    var userRole = responseRole.data;

    batchActions([
      dispatch(UpdateSlug(userSlug)),

      dispatch(UpdateAuth(auth)),

      dispatch(UpdateRole(userRole)),

      loadCarrySByType(dispatch, auth, userSlug, 1, null), //weight gain
      loadCarrySByType(dispatch, auth, userSlug, 2, null), //weeks held

      loadNotifications(dispatch, auth, userSlug),

      loadGroups(dispatch, auth, userSlug),

      loadCarryS(dispatch, auth, userSlug),

      loadTrades(dispatch, auth, userSlug),
    ]);
  } catch (error) {
    console.error("Load Error");

    dispatch(FetchUserError("Cannot load user"));
  }
}

async function addUserCmd(dispatch, auth, newUser) {
  try {
    dispatch(SavingUser(true));

    const headers = { Authorization: `Bearer ${auth}` };

    console.log(newUser);
    var addURL = `${process.env.REACT_APP_API_DOMAIN}/api/Users`;
    const response = await axios.post(addURL, newUser, { headers });

    dispatch(SavedUser(false));
  } catch (error) {
    console.error("Load Error");

    dispatch(FetchUserError("Cannot save user"));
  }
}

export const updateUser = (newUser) => (dispatch) => {
  dispatch(UpdateUser(newUser));
};

export const updateSlug = (newSlug) => (dispatch) => {
  dispatch(UpdateSlug(newSlug));
};

export const updateLogin = (auth, userId) => (dispatch) => {
  upateLoginSlug(dispatch, auth, userId);
};

export const addUser = (auth, newUser) => (dispatch) => {
  addUserCmd(dispatch, auth, newUser);
};

export const changeUserDraft = (newValue) => (dispatch) => {
  dispatch(UserChangeDraft(newValue));
};

export const changeAdminDraft = (newValue) => (dispatch) => {
  dispatch(AdminChangeDraft(newValue));
};
