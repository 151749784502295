import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";

import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import routes from "./routes/routes";
import ThemeRoutes from "./routes/routing";
import indexRoutes from "./routes/index";
import LandingLayout from "./layouts/landingLayout.jsx";
import Fulllayout from "./layouts/fulllayout.jsx";
import BasicInfoView from "./views/info/basicInfo.jsx";
import MainMenuView from "./views/info/mainMenu.jsx";
import Home from "./views/home/home";

//import { createBrowserHistory } from "history";

const container = document.getElementById("root");
const root = createRoot(container);

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" key="Dashboard" element={<LandingLayout />} />

      <Route
        path="/home"
        key="Dashboard"
        element={<Home />}
        //element={<ProtectedRoute component={Home} />}
      />

      <Route
        key="fulllayout"
        // elemnent={<div component={Fulllayout} />}
        element={<ProtectedRoute component={Fulllayout} />}
      >
        {/* <Route path="/user/main" key="main" element={<BasicInfoView />} /> */}

        {ThemeRoutes.map((prop, key) => {
          if (prop.navlabel) {
            return null;
          } else if (prop.collapse) {
            return prop.child.map((prop2, key2) => {
              if (prop2.collapse) {
                return prop2.subchild.map((prop3, key3) => {
                  return;
                  // !this.state.auth.isAuthenticated() ? (
                  //   <redirect to="/login" />
                  // ) : (

                  <Route
                    path={prop3.path}
                    element={prop3.component}
                    //auth={this.state.auth}
                    key={key3}
                  />;
                  // );
                });
              }
              //return;
              // !this.state.auth.isAuthenticated() ? (
              //   <redirect to="/login" />
              // ) : (
              return (
                <Route
                  path={prop2.path}
                  element={prop2.component}
                  //auth={this.state.auth}
                  key={key2}
                />
              );
              // );
            });
            // } else if (prop.redirect) {
            //   return (
            //     <redirect from={prop.path} to={prop.pathTo} key={key} />
            //   );
          } else {
            //return;
            // !this.state.auth.isAuthenticated() ? (
            //   <redirect to="/login" />
            // ) : (
            return (
              <Route
                path={prop.path}
                element={prop.component}
                //auth={this.state.auth}
                key={key}
              />
            );
            // );
          }
        })}
      </Route>
    </Routes>
  );
};
//)
//);

root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router}> */}

    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain="klr.au.auth0.com" //klr23.au.auth0.com" //domain="klr.au.auth0.com"
          clientId="lcCvCgWWFiFl5sL3p6VlowomZze2ZI2Q" // ClientId (klr23)="5IwyJ0udDyFl2kn1ptVI2xoZGVM6Qeuf" //clientId (klr)="lcCvCgWWFiFl5sL3p6VlowomZze2ZI2Q"
          authorizationParams={{
            audience: "https://dev-api-klr.com.au",
            redirect_uri: window.location.origin,
          }}
        >
          <MyRoutes />

          {/* <RouterProvider router={router} /> */}
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </Provider>

    {/* </RouterProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
